.footer {
  color: #fff;
  background-color: #333;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
  display: flex;
}

.footer-section {
  flex: 1;
  margin: 10px;
}

.footer-section h3 {
  margin-bottom: 10px;
}

.footer-section ul {
  padding: 0;
  list-style-type: none;
}

.footer-section ul li a {
  color: #fff;
  text-decoration: none;
}

.footer-section ul li a:hover {
  text-decoration: underline;
}

.social-icons img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.footer-section form {
  flex-direction: column;
  display: flex;
}

.footer-section form input[type="email"] {
  margin-bottom: 10px;
  padding: 5px;
}

.footer-section form input[type="submit"] {
  color: #fff;
  cursor: pointer;
  background-color: #555;
  border: none;
  padding: 5px 10px;
}

.footer-section form input[type="submit"]:hover {
  background-color: #777;
}
/*# sourceMappingURL=index.6ced7a63.css.map */
