
.footer {
    background-color: #333;
    color: #fff;
    padding: 20px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
   }
   
   .footer-section {
    flex: 1;
    margin: 10px;
   }
   
   .footer-section h3 {
    margin-bottom: 10px;
   }
   
   .footer-section ul {
    list-style-type: none;
    padding: 0;
   }
   
   .footer-section ul li a {
    color: #fff;
    text-decoration: none;
   }
   
   .footer-section ul li a:hover {
    text-decoration: underline;
   }
   
   .social-icons img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
   }
   
   .footer-section form {
    display: flex;
    flex-direction: column;
   }
   
   .footer-section form input[type="email"] {
    margin-bottom: 10px;
    padding: 5px;
   }
   
   .footer-section form input[type="submit"] {
    padding: 5px 10px;
    background-color: #555;
    color: #fff;
    border: none;
    cursor: pointer;
   }
   
   .footer-section form input[type="submit"]:hover {
    background-color: #777;
   }
   